// @ts-nocheck
import React from "react";

// swiper - css
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

function Footer({ socialLink }) {
  return (
    <div className="mt-auto bg-black">
      {/* Partner */}
      {/* Footer */}
      <div className="bg-black bg-opacity-50 border-top border-secondary px-4 py-1 d-flex justify-content-between align-items-center">
        <div className="opacity-50">
          {/* FB  */}
          <a
            href={socialLink?.facebook}
            className="text-decoration-none pe-3 text-info"
          >
            <i className="fa-brands fs-3 fa-facebook"></i>
          </a>
          {/* Messenger */}
          <a
            href={socialLink?.messenger}
            className="text-decoration-none pe-3 text-info"
          >
            <i className="fa-brands fs-3 fa-facebook-messenger"></i>
          </a>
          {/* Viber */}
          <a
            href={socialLink?.viber}
            className="text-decoration-none pe-3 text-info"
          >
            <i className="fa-brands fs-3 fa-viber"></i>
          </a>
          {/* Instagram */}
          <a
            href={socialLink?.instagram}
            className="text-decoration-none pe-3 text-info"
          >
            <i className="fa-brands fs-3 fa-telegram"></i>
          </a>
        </div>
        <div>
          <small className="fw-bold text-info opacity-75">
            © Copyright Bawdar
          </small>
        </div>
      </div>
    </div>
  );
}

export default Footer;
