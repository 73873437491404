// @ts-nocheck
import React, { useEffect, useState } from "react";
// Lib
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
// Api
import {
  getPocketWinnerHistoryApi,
  getWinnerListHistoryApi,
} from "../api/historyApi";
// Comp
import Loading from "../components/shared_comp/Loading";
import WinnerListHistory from "../components/winner_list/WinnerListHistory";
import axios from "axios";
import { PocketWinner } from "../api/apiUrl";
import moment from "moment";
import PocketWinnerList from "../components/winner_list/PocketWinnerList";

function WinnerList() {
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState("C2D");
  const [history, setHistory] = useState([]);
  const [section, setsection] = useState("");
  const [date, setDate] = useState({
    start: new Date(),
    end: new Date(),
  });

  const historyTypes = [
    {
      type: "C2D",
      name: "Crypto 2D",
    },
    {
      type: "C1D",
      name: "Crypto 1D",
    },
    {
      type: "1D",
      name: "Thai 1D",
    },
    {
      type: "2D",
      name: "Thai 2D",
    },
    {
      type: "3D",
      name: "Thai 3D",
    },
    {
      type: "pocket",
      name: "Pocket Winners",
    },
  ];

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    setLoading(true);
    // Fetch Historys

    if (selected === "pocket") {
      let from = moment(date.start).format("YYYY-MM-DD");
      getPocketWinnerHistoryApi({ from, signal })
        .then((res) => {
          setHistory(
            res.data.sort((a, b) => {
              return parseInt(b.promo_amount) - parseInt(a.promo_amount);
            })
          );
        })
        .catch((_) => setHistory([]))
        .finally(() => setLoading(false));
    } else {
      getWinnerListHistoryApi({
        signal,
        type: selected,
      })
        .then((res) => {
          setsection(res);
          let data = res?.data || [];
          setHistory(data);
        })
        .catch((_) => setHistory([]))
        .finally(() => setLoading(false));
    }
    return () => controller.abort("aborted history request");
  }, [date.start, selected]);

  let { t } = useTranslation();
  return (
    <div className="mx-2 mx-md-0">
      <div className="container pt-2 mt-3 bg-dark bg-opacity-75 rounded-3 border border-dark">
        {/* History Filter Section */}
        <div className="row mt-4">
          <div className="col-6 offset-3 offset-md-4 col-md-4">
            <select
              value={selected}
              onChange={(e) => setSelected(e.target.value)}
              className="form-select form-select-sm py-2 px-3 rounded-3 bg-dark fw-bold text-warning border border-2 border-warning"
            >
              {historyTypes?.map(({ type, name }, _index) => (
                <option key={_index} value={type}>
                  {name}
                </option>
              ))}
            </select>
          </div>

          <div className="d-flex justify-content-between align-items-center">
            <div className="">
              <h5 className="text-warning">
                <span className="d-md-inline d-none fw-bold text-white">
                  {t("winnerListHistory.section")} :
                </span>{" "}
                <span className="text-warning">
                  {section.section ? section.section : "_ _"}
                </span>{" "}
              </h5>
            </div>
            <div className="d-flex justify-content-center align-items-center">
              <h5 className="text-white fw-bold">
                {t("winnerListHistory.lucky_number")}
              </h5>
              <div
                style={{ height: "50px", width: "50px", fontSize: "15px" }}
                className="ms-2 d-flex align-items-center justify-content-center bg-warning rounded-circle border border-3 border-warning text-black"
              >
                <b className="text-black">
                  {section.lucky_number ? section.lucky_number : "_ _"}
                </b>
              </div>
            </div>
          </div>
        </div>
        {/* History Section */}
        {loading ? (
          <div className="py-3">
            <Loading loading={loading} />
          </div>
        ) : selected === "pocket" ? (
          <PocketWinnerList history={history} date={date} setDate={setDate} />
        ) : (
          <WinnerListHistory history={history} />
        )}
      </div>
    </div>
  );
}

export default WinnerList;
